import React, { Suspense, lazy, useEffect, useState } from "react";

import { useAuth } from "./context/authContext";

import "./index.css";

const UnprotectedRoutes = lazy(() => import("./routes/UnprotectedRoutes"));
const ClientRoutes = lazy(() => import("./routes/ClientRoutes"));
const AdminRoutes = lazy(() => import("./routes/AdminRoutes"));

const Loading = () => null;

const App = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    demoAsyncCall().then(() => setIsLoading(false));
  });
  if (isLoading) {
    return <Loading />;
  } else if (user == null) {
    return (
      <Suspense fallback={<Loading />}>
        <UnprotectedRoutes />
      </Suspense>
    );
  } else if (user.is_admin === 1) {
    return (
      <Suspense fallback={<Loading />}>
        <AdminRoutes isAuthenticated={true} />
      </Suspense>
    );
  } else if (user.is_admin === 0) {
    return (
      <Suspense fallback={<Loading />}>
        <ClientRoutes isAuthenticated={true} />
      </Suspense>
    );
  }
};
function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 500));
}

export default App;
