import React, { createContext, useContext, useEffect, useState } from "react";

import Toast from "../components/Toast";
import routeLinks from "../routes/routeLinks";

export const AuthContext = createContext({
  user: {},
  setUser: () => {},
  handleErrorLogout: () => {},
});

const AuthProvider = (props) => {
  const [user, setUser] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const handleErrorLogout = (error) => {
    if (!error.response) {
      setToastSeverity("error");
      setToastMessage("サーバーに接続できませんでした。");
      setShowToast(true);
      return;
    }
    if (error.code === "ECONNABORTED") {
      setToastSeverity("error");
      setToastMessage(
        "サーバーのタイムアウトが発生しました。再度お試しください。"
      );
      setShowToast(true);
    }
    if (500 === error.response.status) {
      setToastSeverity("error");
      setToastMessage("エラーコード500：サーバーでエラーが発生しました。");
      setShowToast(true);
    }
    if (401 === error.response.status) {
      localStorage.removeItem("userData");
      if (!user) {
        window.location.href = routeLinks.top;
      } else if (user.is_admin === 1) {
        setUser();
        window.location.href = routeLinks.admin.login;
      } else if (user.is_admin === 0) {
        setUser();
        window.location.href = routeLinks.client.login;
      }
    }
  };
  const toast = (severity = "error", message) => {
    setToastSeverity(severity);
    setToastMessage(message);
    setShowToast(true);
  };
  const value = {
    user,
    setUser,
    handleErrorLogout,
    toast,
  };
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
  }, [setUser]);
  return (
    <AuthContext.Provider value={value} {...props}>
      {props.children}
      <Toast
        open={showToast}
        onClose={() => setShowToast(false)}
        message={toastMessage}
        severity={toastSeverity}
      />
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export { AuthProvider, useAuth };
