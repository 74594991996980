import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { AppProvider } from "./context/appContext";
import { AuthProvider } from "./context/authContext";

require("es6-promise/auto");
require("date-time-format-timezone");

// import "./wdyr";
// import "./wdyu";

ReactDOM.render(
  <AuthProvider>
    <AppProvider>
      <App />
    </AppProvider>
  </AuthProvider>,
  document.getElementById("root")
);
