// CLIENT
export const LOGIN_ENDPOINT = `/api/login/`;
export const LOGOUT_ENDPOINT = `/api/logout/`;
export const READ_TOS_ENDPOINT = `/api/tos/`;

export const STATION_SEARCH_ENDPOINT = `/api/stations/`;
export const REPORT_CREATE_ENDPOINT = `/api/reports/`;

export const GET_REPORT_LIST_ENDPOINT = `/api/reports/`;
export const GET_REPORT_DETAIL_ENDPOINT = `/api/reports/`;
export const GET_REPORT_ACCESS_COUNT_ENDPOINT = `/api/report_accesses/counts/`;
export const UPDATE_REPORT_SELECTED_PAGES_ENDPOINT = `/api/reports/`;

export const URL_COPY_ENDPOINT = `/api/url_copy/`;
export const DOWNLOAD_PDF_ENDPOINT = `/api/pdf/`;
export const UPDATE_MEMO_1_ENDPOINT = `/api/reports/`;
export const UPDATE_MEMO_2_ENDPOINT = `/api/reports/`;

export const CHANGE_PASSWORD_ENDPOINT = `/api/password/change/`;
export const GET_LOGIN_LOGS_ENDPOINT = `/api/logs/logins/`;
export const GET_REPORT_VIEW_LOGS_ENDPOINT = `/api/logs/reports_views/`;
export const GET_REPORT_LOGS_ENDPOINT = `/api/logs/reports/`;

export const GET_NOTIFICATION_LIST_ENDPOINT = `/api/notifications/`;
export const GET_NOTIFICATION_DETAIL_ENDPOINT = `/api/notifications/`;

// ADMIN
export const ADMIN_LOGIN_ENDPOINT = `/api/admin/login/`;
export const ADMIN_LOGOUT_ENDPOINT = `/api/admin/logout/`;

export const ADMIN_GET_COMPANY_LIST_ENDPOINT = `/api/admin/companies/`;
export const ADMIN_ADD_COMPANY_ENDPOINT = `/api/admin/companies/`;
export const ADMIN_EDIT_COMPANY_ENDPOINT = `/api/admin/companies/`;

export const ADMIN_GET_USER_LIST_ENDPOINT = `/api/admin/users/`;
export const ADMIN_ADD_USER_ENDPOINT = `/api/admin/users/`;
export const ADMIN_EDIT_USER_ENDPOINT = `/api/admin/users/`;
export const ADMIN_GET_LOGIN_LOGS_ENDPOINT = `/api/admin/logs/logins/`;
export const ADMIN_GET_REPORT_VIEW_LOGS_ENDPOINT = `/api/admin/logs/reports_views/`;
export const ADMIN_GET_REPORT_LOGS_ENDPOINT = `/api/admin/logs/reports/`;

export const ADMIN_GET_NOTIFICATION_LIST_ENDPOINT = `/api/admin/notifications/`;
export const ADMIN_ADD_NOTIFICATION_ENDPOINT = `/api/admin/notifications/`;
export const ADMIN_GET_NOTIFICATION_DETAIL_ENDPOINT = `/api/admin/notifications/`;
export const ADMIN_EDIT_NOTIFICATION_ENDPOINT = `/api/admin/notifications/`;

export const ADMIN_CHANGE_PASSWORD_ENDPOINT = `/api/admin/password/change/`;

export const ADMIN_CSV_LOGIN_ENDPOINT = `/api/admin/log_download/logins/`;
export const ADMIN_CSV_REPORT_VIEWS_ENDPOINT = `/api/admin/log_download/reports_views/`;
export const ADMIN_CSV_REPORT_CREATES_ENDPOINT = `/api/admin/log_download/report_creates/`;
export const ADMIN_CSV_REPORT_CLIENTS_ENDPOINT = `/api/admin/log_download/report_clients/`;
export const ADMIN_CSV_REPORT_ACCESSES_ENDPOINT = `/api/admin/log_download/report_accesses/`;

// USER
export const REPORT_PAGE_ENDPOINT = `/api/report_pages/`;
export const REPORT_SUMMARY_ENDPOINT = `/api/summaries/`;
