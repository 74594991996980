import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import routeLinks from "../routes/routeLinks";
import clientService from "../services/clientService";
import { useAuth } from "./authContext";

export const AppContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
  newAccessCount: 0,
  setNewAccessCount: () => {},
  getNewAccessCount: () => {},
});

const AppProvider = (props) => {
  const { user, handleErrorLogout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [newAccessCount, setNewAccessCount] = useState(0);
  const getNewAccessCount = useCallback(async () => {
    let count = await clientService
      .getReportAccessCount()
      .then((res) => res.count)
      .catch((error) => handleErrorLogout(error));
    setNewAccessCount(count);
  }, [handleErrorLogout]);
  useEffect(() => {
    if (
      user != null &&
      Object.values(routeLinks.client).includes(window.location.pathname)
    ) {
      getNewAccessCount();
    }
  }, [user, getNewAccessCount, props.children]);
  const value = {
    isLoading,
    setIsLoading,
    newAccessCount,
    setNewAccessCount,
    getNewAccessCount,
  };
  return <AppContext.Provider value={value} {...props} />;
};

const useApp = () => {
  const context = useContext(AppContext);
  return context;
};

export { AppProvider, useApp };
