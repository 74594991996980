import axios from "axios";

import { API_BASE_URL } from "../constants";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  if (localStorage.getItem("userData") != null) {
    const access_token = JSON.parse(localStorage.getItem("userData"))
      .access_token;
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
});

export default axiosInstance;
