export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const downloadBlob = async (blob, type, fileName) => {
  var newBlob = new Blob([blob], { type: type });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, fileName);
    return;
  }
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement("a");
  link.href = data;
  link.target = "_blank";
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  await delay(100);
  link.remove();
};
