import { REPORT_STATUS } from "../constants";
import { downloadBlob } from "../utils/downloadBlob";
import axiosInstance from "./axiosInstance";
import * as endpoints from "./endpoints";

const clientService = {
  searchStation: async function ({ latitude, longitude }) {
    let config = {
      params: {
        latitude: latitude,
        longitude: longitude,
      },
    };
    return await axiosInstance
      .get(endpoints.STATION_SEARCH_ENDPOINT, config)
      .then((res) => {
        return res.data.data.stations;
      });
  },
  createReport: async function ({
    latitude,
    longitude,
    station,
    statistics,
    disaster,
    did_select_pages,
  }) {
    let payload = {
      latitude: latitude,
      longitude: longitude,
      station: station,
      statistics: statistics,
      disaster: disaster,
      did_select_pages: did_select_pages,
    };
    return await axiosInstance
      .post(endpoints.REPORT_CREATE_ENDPOINT, payload)
      .then((res) => {
        return res.data;
      });
  },
  createSelectedReport: async function ({ report_id, selected }) {
    let payload = {
      selected: selected,
    };
    return await axiosInstance
      .patch(
        `${endpoints.UPDATE_REPORT_SELECTED_PAGES_ENDPOINT}${report_id}`,
        payload
      )
      .then((res) => res.data);
  },
  getReports: async function () {
    return await axiosInstance
      .get(endpoints.GET_REPORT_LIST_ENDPOINT)
      .then((res) => {
        return res.data.data;
      });
  },
  getReportDetail: async function (report_id) {
    return await axiosInstance
      .get(`${endpoints.GET_REPORT_DETAIL_ENDPOINT}${report_id}`)
      .then((res) => {
        return res.data.data;
      });
  },
  copyUrl: async function (report_id) {
    return await axiosInstance
      .post(`${endpoints.URL_COPY_ENDPOINT}${report_id}`)
      .then((res) => res);
  },
  downloadPdf: async function (report_id) {
    return await axiosInstance
      .get(`${endpoints.DOWNLOAD_PDF_ENDPOINT}${report_id}`, {
        responseType: "blob",
        headers: { Accept: "application/pdf, application/json" },
      })
      .then((res) => {
        if (res.status === 202) {
          return REPORT_STATUS.processing;
        } else {
          const prefix = "UTF-8''";
          const fileName = decodeURIComponent(
            res.headers["content-disposition"].slice(
              res.headers["content-disposition"].search(prefix) + prefix.length
            )
          );
          downloadBlob(res.data, "application/pdf", fileName);
        }
      });
  },
  updateMemo1: async function ({ report_id, text }) {
    let payload = { memo: text };
    return await axiosInstance
      .patch(`${endpoints.UPDATE_MEMO_1_ENDPOINT}${report_id}`, payload)
      .then((res) => res.data.data);
  },
  updateMemo2: async function ({ report_id, text }) {
    let payload = { detail_memo: text };
    return await axiosInstance
      .patch(`${endpoints.UPDATE_MEMO_2_ENDPOINT}${report_id}`, payload)
      .then((res) => res.data.data);
  },
  getReportAccessCount: async function () {
    return await axiosInstance
      .get(endpoints.GET_REPORT_ACCESS_COUNT_ENDPOINT)
      .then((res) => res.data.data);
  },
  getLoginLogs: async function ({ targetMonth }) {
    let config = {
      params: {
        target_month: targetMonth,
      },
    };
    return await axiosInstance
      .get(endpoints.GET_LOGIN_LOGS_ENDPOINT, config)
      .then((res) => res.data.data);
  },
  getReportViewLogs: async function ({ targetMonth }) {
    let config = {
      params: {
        target_month: targetMonth,
      },
    };
    return await axiosInstance
      .get(endpoints.GET_REPORT_VIEW_LOGS_ENDPOINT, config)
      .then((res) => res.data.data);
  },
  getReportLogs: async function ({ targetMonth }) {
    let config = {
      params: {
        target_month: targetMonth,
      },
    };
    return await axiosInstance
      .get(endpoints.GET_REPORT_LOGS_ENDPOINT, config)
      .then((res) => res.data.data);
  },
  getNotifications: async function () {
    return await axiosInstance
      .get(endpoints.GET_NOTIFICATION_LIST_ENDPOINT)
      .then((res) => {
        return res.data.data;
      });
  },
  getNotificationDetail: async function (notification_id) {
    return await axiosInstance
      .get(`${endpoints.GET_NOTIFICATION_DETAIL_ENDPOINT}${notification_id}`)
      .then((res) => {
        return res.data.data;
      });
  },
};

export default clientService;
