const routeLinks = {
  top: "/",
  user: {
    report: "/report",
    pdf: "/pdf",
    terms: "/terms",
  },
  client: {
    login: "/login",
    reportCreate: "/report-create",
    reportList: "/report-list",
    account: "/account",
    logs: "/logs",
  },
  admin: {
    login: "/admin/login",
    userManagement: "/admin/user-management",
    companyManagement: "/admin/company-management",
    logs: "/admin/logs",
    notifications: "/admin/notifications",
    account: "/admin/account",
  },
};

export default routeLinks;
