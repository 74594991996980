export const GOOGLEMAP_API_KEY = process.env.REACT_APP_GOOGLEMAP_API_KEY;

export const GOOGLEMAP_GEOCODE_API_KEY =
  process.env.REACT_APP_GOOGLEMAP_GEOCODE_API_KEY;

export const REPORT_BASE_URL = `${process.env.REACT_APP_BASE_URL}/report`;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const CLIENT_NAVBAR_INDEXES = {
  reportCreate: 0,
  reportList: 1,
  logs: 2,
  accountSettings: 3,
};

export const ADMIN_NAVBAR_INDEXES = {
  logs: 0,
  companyManagement: 1,
  userManagement: 2,
  notificationManagement: 3,
  accountSettings: 4,
};

export const REPORT_DATES = {
  POPULATION_START: 2015,
  POPULATION_END: 2020,
};

export const REPORT_STATUS = {
  not_requested: "未作成",
  processing: "作成中",
  failure: "失敗",
  success: "DL可",
};

export const REPORT_PAGE_MAX_SCORES = {
  facilities_location: 25,
  facilities_station: 15,
  land_price: 13,
  population: 13,
  household: 7,
  population_under_eighteen: 10,
  population_eighteen_to_sixtyfour: 10,
  average_age: 7,
  average_household_income: null,
  flood: 10,
  landslide: 10,
  earthquake: 10,
  liquefaction: 10,
  evacuation_facilities: 10,
  active_fault: null,
  land_use: null,
  summary_statistics: 100,
  summary_disaster: 50,
};

export const STATISTICS_PAGES = [
  "summary_statistics",
  "facilities_location",
  "facilities_station",
  "land_price",
  "population",
  "household",
  "population_under_eighteen",
  "population_eighteen_to_sixtyfour",
  "average_age",
  "average_household_income",
];

export const DISASTER_PAGES = [
  "summary_disaster",
  "flood",
  "landslide",
  "earthquake",
  "liquefaction",
  "evacuation_facilities",
  "active_fault",
  "land_use",
];

export const REPORT_PAGE_MAPPING = {
  facilities_location: "施設充実度：対象地点周辺",
  facilities_station: "施設充実度：近隣駅周辺",
  land_price: "地価",
  population: "人口",
  household: "世帯",
  population_under_eighteen: "18歳未満人口",
  population_eighteen_to_sixtyfour: "18歳~65歳未満人口",
  average_age: "平均年齢",
  average_household_income: "参考：平均世帯年収",
  flood: "浸水想定",
  landslide: "土砂災害",
  earthquake: "想定震度",
  liquefaction: "液状化",
  evacuation_facilities: "避難施設",
  active_fault: "参考：活断層",
  land_use: "参考：土地利用",
  summary_statistics: "まとめ（周辺施設・統計）",
  summary_disaster: "まとめ（災害関連）",
};

export const REPORT_PAGE_ORDER = {
  facilities_location: 2,
  facilities_station: 3,
  land_price: 4,
  population: 5,
  household: 6,
  population_under_eighteen: 7,
  population_eighteen_to_sixtyfour: 8,
  average_age: 9,
  average_household_income: 10,
  flood: 12,
  landslide: 13,
  earthquake: 14,
  liquefaction: 15,
  evacuation_facilities: 16,
  active_fault: 17,
  land_use: 18,
  summary_statistics: 1,
  summary_disaster: 11,
};

export const REPORT_URL_MAPPING = {
  facilities_location: "facilities-location",
  facilities_station: "facilities-station",
  land_price: "land-price",
  population: "population",
  household: "household",
  population_under_eighteen: "population-under-eighteen",
  population_eighteen_to_sixtyfour: "population-eighteen-to-sixtyfour",
  average_age: "average-age",
  average_household_income: "average-household-income",
  flood: "flood",
  landslide: "landslide",
  earthquake: "earthquake",
  liquefaction: "liquefaction",
  evacuation_facilities: "evacuation-facilities",
  active_fault: "active-fault",
  land_use: "land-use",
  summary_statistics: "summary-statistics",
  summary_disaster: "summary-disaster",
};

export const PAGE_TYPES = {
  facilitiesLocation: "facilities_location",
  facilitiesStation: "facilities_station",
  landPrice: "land_price",
  population: "population",
  household: "household",
  populationUnderEighteen: "population_under_eighteen",
  populationEighteenToSixty: "population_eighteen_to_sixtyfour",
  averageAge: "average_age",
  averageHouseholdIncome: "average_household_income",
  flood: "flood",
  landslide: "landslide",
  earthquake: "earthquake",
  liquefaction: "liquefaction",
  evacuationFacilities: "evacuation_facilities",
  activeFault: "active_fault",
  landUse: "land_use",
  summaryStatistics: "summary_statistics",
  summaryDisaster: "summary_disaster",
};

export const PAGE_URLS = {
  facilitiesLocation: "facilities-location",
  facilitiesStation: "facilities-station",
  landPrice: "land-price",
  population: "population",
  household: "household",
  populationUnderEighteen: "population-under-eighteen",
  populationEighteenToSixty: "population-eighteen-to-sixtyfour",
  averageAge: "average-age",
  averageHouseholdIncome: "average-household-income",
  flood: "flood",
  landslide: "landslide",
  earthquake: "earthquake",
  liquefaction: "liquefaction",
  evacuationFacilities: "evacuation-facilities",
  activeFault: "active-fault",
  landUse: "land-use",
  summaryStatistics: "summary-statistics",
  summaryDisaster: "summary-disaster",
  cover: "cover",
  terms: "terms",
  imprint: "imprint",
  middleCoverStatistics: "middle-cover-statistics",
  middleCoverDisaster: "middle-cover-disaster",
};
